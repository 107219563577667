import React from 'react';
import { SlideShowHeader } from '../SlideShowHeader/SlideShowHeader';

import './Header.css';

export const Header = () => {

  return (
    <section id='home-section' className='header-outer-container'>
      <SlideShowHeader />
    </section>
  );
} 
