export const siteData = {
  rates: [
    {
      name: "Hilton Phoenix Resort at the Peak",
      arrival: "Arrive: April 12, 2024",
      departure: "Depart: April 14, 2024",
      bullets: [
        "$249/night + tax for one-bedroom suite with either single king bed or two queen beds, pullout sofa bed in both, both rooms are the same price",
        "$25/night (reduced from $35) + tax nightly resort fee (wi-fi, parking, fitness center, pools, water park, mini-golf)",
        "$9/person one-time porterage and $2 nightly housekeeping service charge",
        "the estimated total cost per room for two nights is $632",
        "to reserve call 1-602-997-2626 and say you're with the \"Nemoff Family Reunion April 12-14, 2024\"",
        "deadline to book is Friday February 23, 2024"
      ]
    },
  ],
  plans: [
    {
      name: "Thursday",
      location: "April 11, 2024",
      time: "more updates to come",
      description: [`
        <strong>4:00pm</strong> Check-in for those coming one day early. <br>
        No official activities planned yet. <br>
        <a
          rel="noopener noreferrer"
          aria-label="venue address"
          href="http://maps.google.com/?q=Hilton Phoenix Resort at the Peak"
          target="_blank"
        >
          <p style={{ fontWeight: 'bold' }}>7677 N 16th St, Phoenix, AZ 85020</p>
        </a>
      `]
    },
    {
      name: "Friday",
      location: "April 12, 2024",
      time: "Official first day | 4pm Check-in | more updates to come",
      description: [`
        <strong>4:20pm</strong> Happy hour & dinner at Aunt Chiladas Squaw Peak restaurant (8 min walk from resort). <br>
        <a
          rel="noopener noreferrer"
          aria-label="dinner address"
          href="https://maps.app.goo.gl/8Z2PDBN92QmHyQt68"
          target="_blank"
        >
          <p style={{ fontWeight: 'bold' }}>7330 N Dreamy Draw Dr, Phoenix, AZ 85020</p>
        </a>
        `,
      ]
    },
    {
      name: "Saturday",
      location: "April 13, 2024",
      time: "more updates to come",
      description: [`
        <strong>4:00pm</strong> Family BBQ at David Myers' house in Sun City. 
        David is surrounded by golf courses and beautiful, giant cacti. 
        We will eat, drink, and be merry. David lives 16 miles west of the resort. 
        We're working on organizing transportation. <br>
          <a
            rel="noopener noreferrer"
            aria-label="dinner address"
            href="https://maps.app.goo.gl/qdGwiM2o96iFf9ZU9"
            target="_blank"
          >
            <p style={{ fontWeight: 'bold' }}>10254 W Pinehurst Dr, Sun City, AZ 85351</p>
          </a>
        `,
      ]
    },
    {
      name: "Sunday",
      location: "April 14, 2024",
      time: "more updates to come",
      description: [
        "Nothing official planned yet. Maybe a nice horse ride through Dreamy Damn Park?"
      ]
    },
  ],
  amenities: [
    {
      list: [
        "4 pools including a lazy river for kids of all ages",
        "River Ranch Water Park access included with room reservation",
        "on-site restaurant, Hole-in-the-Wall",
        "gym and health club",
        "full service spa",
        "horseback riding in Dreamy Draw Park",
        "easy walk to and around the north Phoenix mountains",
        "for those in training, a walk (or run) up Piestewa Peak",
        "new, innovative Mexican food and fusion",
        "golf and putting greens",
        "legal recreational cannabis in Arizona :)",
        "BEST OF ALL: quality time with cool cousins and extended fam!",
      ]
    }
  ],
  roster: [
    {
      list: [
        "Sara Wade, Matteo Wade",
        "Adam Wade, Azana Nunez (arriving Thur)",
        "Greg Nemoff, Kathy Garramone",
        "Nina Nemoff, Josh Page, Lennon Page, Maeve Page",
        "Terry Nemoff, Caden Nemoff",
        "Zachary Nemeroff, Celene Nemeroff, Xander Nemeroff (arriving Thur)",
        "Mitch Nemeroff, Brenda Nemeroff",
        "Howard Nemeroff, Nolan Nemeroff",
        "Judy Feinberg",
        "Zachary Myers, Rachel Myers, Blake Myers (arriving Thur)",
        "Josh Myers, Elenor Myers, Michelle Siles (arriving Thur)"
      ]
    }
  ]
}
